.carousel-galleria-section{
    height: fit-content;
   
    width: 100%;
    left: 0;
    
}

.inner-carousel-section{
    height: inherit;
    overflow-y: hidden;
    cursor: pointer;
    
    &::-webkit-scrollbar{
        display: none;
    }
}

.carousel-container{
    @include flex(column,center,space-between);
    scroll-snap-type: x mandatory;
    height: inherit;

    @media screen and (max-width:$s) {
        flex-direction: row;
        width: fit-content;
    }
}

.carousel{
    height: 345px;
    width: 100%;
    display: flex;
    gap: 16px;
    scroll-snap-align: start;

        @media screen and (max-width:$s) {
            height: 352px;
            padding: 0 7px;
        }
}

.second-carousel{
    @media screen and (min-width: ($s + 1)) {
        margin-top: 16px;
    }
   
}

.img-card-container{
    width: 260px;
    height: 100%;
    min-width: 260px;
}



// ::-webkit-scrollbar{  
//    display: none;
// }


.img-card-container{
    position: relative; 
}



img:not(.logo img){
    width: 100%;
    height: 100%;
    border-radius: 16px;
}


.over-card{

    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    z-index: 10;
}


.img-carousel-galleria{
    object-fit: cover;
}

