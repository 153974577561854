:root{
    --buttonNav-Hover:#242423;
    --buttonNav-normal:#E8EDDF;
    --buttonNav-iconwhatsapp:#F5CB5C;
    
}

.nav-item__link{
text-decoration: none;
}


.nav{

    ul.nav-container{
        @include flex(row,center,center);
        gap:30px;
        margin: 0;
    }
    position: relative;
    z-index: 10;

    
    
    @media screen and (max-width:768px) {
        
            position: absolute;
            top: 100%;
            width: 100vw;
        
            ul.nav-container{
                gap: 0px;  
                justify-content: space-evenly;
                background-color:#242423;
                padding-bottom: 10px;


            .nav-item{
                min-width: fit-content;
                background-color: #333533;;
                text-align: center;
                // padding: 10px 10px; 
            }

        }
    }

}


@media screen and (min-width:768px) and (max-width:1024px)  {
    ul.nav-container{

        .nav-item{
            font-size: 12px;
            min-width: fit-content;
            background-color: #333533;;
            text-align: center;
            // padding: 10px 10px; 
        }
    }
}


   

.nav-item{
    // padding: 5px 16px;
    letter-spacing: 1.28px;
    font-size: clamp(12px,0.9vw,16px);
    border: 2px solid $primarySurface;
    border-radius: 16px;
    text-align: center;
    transition: border-color 0.4s ease;
    font-family: Lato_Regular;
    font-style: normal;

    .nav-item__link{
        display: inline-block;
         padding: 2px 16px;

         @media screen and (max-width:$s) {
           
             padding: 10px 5px;
           
        }
    }


        &:not(.nav-item-button-contact){
            @media screen and (max-width:$s) {
                font-size: 10px;
                // padding: 10px 5px;
                width: 82px;
                letter-spacing: 0.1;
            }
        }

    
        &:not(.nav-item-button-contact):hover{
            border-color:$borderColor;   
        }


        a.nav-item__link{
            color: $textNav;
    }

}



.nav-item--big{
    background-color:var(--buttonNav-normal);
    font-family: Lato_Black;
    font-size: 14;
    padding: 10px 32px;
    border-radius: 24px;
    color: $primarySurface;
    @include flexCenter;
    gap: 10px;
    letter-spacing: 1.12px;
    border: 2px solid var(--buttonNav-Hover);
    font-weight: 900;
    font-style: normal;

    @media screen and (max-width:$s) {
        font-size: 12px;
        padding : 7px 16px
    }


    &:hover{
        --buttonNav-Hover:#E8EDDF;
        --buttonNav-normal:#242423;
        --buttonNav-iconwhatsapp:#242423;

            & .icon-whatsapp{
                background: url(/public/assets/icons/WA_icon_button_inactive.svg);
                background-size: contain;
            }        
    }


    &:active{
        border: 2px solid $brandColor;
    }
    


    .text-link-Btn-Nav{
        color: var(--buttonNav-Hover);
    }

}


.icon-whatsapp{
    width: 27.73px;
    height: 27.73px;
    background: url(/public/assets/icons/WA_icon_button_active.svg);
    background-size: contain;

    @media screen and (max-width:$s) {
        width: 19.23;
       height: 19.23;
    }
   
}

.text-link-Btn-Nav{
    margin: 0;
}





