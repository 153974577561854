//Responsive 

@media screen and (max-width:$s) {
   

    .padding-140:not(header){
        padding: 0 16px;
    }

}



// min 1020  max 1366 

@media screen and (min-width:1025px) and (max-width:$lg) {
    
    .info-container {
        width: 366px;
    }
    
    
    
    form.form-contact input#input-date, form.form-contact input#input-hour, form.form-contact input#submit {
        width: 180px;
    }
    
    
    .text-info-item { 
        margin-left: 10px;
     }

     .text-info-item-openTime{
        font-size: 1rem;
        margin-left: 2px;
     }
    
    
    .map {
      
        width: 70%;
        min-width: 252px;
    }
   

    #submit, label[for=input-file]{
        font-size: 10px;
    }


    .studio-gallery-slider--grid {
        
        justify-content: center;
    }

    .btn-underTitle-studio-section{
        line-height: normal;
    }
}

@media screen and (min-width:1070px) and (max-width:1115px) {
    .info-body-inner--flex {
        gap: 60px;
    }
    
}

@media screen and (min-width:1025px) and (max-width:1070px) {
    .info-body-inner--flex {
        gap: 40px;
    }
    
}
 


@media screen and (min-width:$lg) and (max-width:1744px) {


    .text-info-item-openTime {
        min-width: 134px;
        width: 250px;
    }
    
}


   
        .studio-gallery-slider--grid {
            @media screen and (min-width:$s) {
            display: grid;
            gap: 16px 17px;
            grid-template-columns: repeat(3, calc(38vw - 107px));
            // 326px 326px 260px 260px 260px 260px ;
            grid-template-rows: 190px 240px 180px 180px 180px 180px;
        }

        @media screen and (min-width:1247px) and (max-width:1400px) {
            display: grid;
            gap: 16px 17px;
            grid-template-columns: repeat(3, calc(35vw - 107px));
            // 326px 326px 260px 260px 260px 260px ;
            grid-template-rows: 20vw 240px 180px 180px 180px 180px;
        }

        @media screen and (min-width:1401px)  {
            display: grid;
            gap: 16px 17px;
            grid-template-columns: repeat(3, calc(38vw - 107px));
            // 326px 326px 260px 260px 260px 260px ;
            grid-template-rows: 20vw 300px 260px 260px 260px 260px;
        }


        @media screen and (min-width:$lg)  {
            display: grid;
            gap: 16px 17px;
            grid-template-columns: repeat(3, calc(34vw - 107px));
            // 326px 326px 260px 260px 260px 260px ;
            grid-template-rows: 15vw 326px 260px 260px 260px 260px;
        }

        @media screen and (min-width:1800px)   {
            display: grid;
            gap: 16px 17px;
            grid-template-columns: repeat(3, 536px);
            // 326px 326px 260px 260px 260px 260px ;
            grid-template-rows: 326px 326px 260px 260px 260px 260px;
        }
    }
       
    