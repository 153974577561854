.inner-default--flex{
    @include flex(row,center,space-between);

        @media screen and (max-width:$s) {
            flex-direction: column;     
            padding-top: 29px;
            
        }

        @media screen and (max-width:389px) {
               
            padding-top: 0;
            
        }
}



.title-description-container{
    flex: 1.3;
    text-align: left;
    font-size: 32px;
    width: fit-content;
    margin-top: 40px;
    @media screen and (max-width:$s) {
       text-align: center;
       margin: auto;
       order: 2;
        
    }
    
}

.title{
    color: #E8EDDF;
    font-size: clamp(74px, 8vw,140px);
    font-family: Lato_Black;
    margin: 0;
    padding: 0;
    line-height: clamp(74px,8vw,126px);
    letter-spacing: -2.8px;
    padding: 60px 0px;
    

    @media screen and (max-width:$s) {
       padding: 0 0 10px 0;
     }

     @media screen and (max-width:$lg) and (min-width:$s) {
        padding-left: 40px;
        padding-bottom: 0px;
      }

     @media screen and (max-width:389px) {
        line-height: clamp(74px,10vw,126px);
      }

}




.title-description{
    color: #e8eddf;
    font-family: Lato_Regular;
    font-size: clamp(16px,2vw,28px);
    text-align: left;
    margin-top: 20px;
    font-weight: 100;
    font-style: normal;
    word-wrap: break-word;
    line-height: clamp(33px,2vw,42px);
    position: relative;
    letter-spacing: 0px;
    font-weight: normal;
    width: 470px;
    margin-left: 32pt;
    
    @media screen and (max-width:389px) {
       font-size: 14px;
      }

      @media screen and (max-width:$lg) {
        font-size: clamp(16px,1.7vw,28px);
        width: 440px;
        padding-bottom: 0;
       }

       @media screen and (max-width:1280px) {
        
        width: 360px;
       }

    
        ::before{
            content: "";
            width: 8px;
            height: 100%;
            background-color: #F5CB5C;
            position: absolute;
            top: 0;
            left: -32pt;
            border-radius: 24px;

            @media screen and (max-width:$s) {
                width: 4px;
                left: -24px;
            }
        }


        @media screen and (max-width:$s) {
            text-align: center;
            margin: auto;
            width: 80%;
            line-height: 22px;
            padding-bottom: 24px;
            
            ::after{
                content: "";
                width: 4px;
                height: 100%;
                background-color: #F5CB5C;
                position: absolute;
                top: 0;
                right: -24px;
                border-radius: 24px;

            }
         }

         @media screen and (min-width:480px) and (max-width:$s) {
            width: 60%;
         }
}


.btn-underTitle{
    margin-top: 48px;
    border: 2px solid #818784;
    height: 48px;
    width: 310px;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    letter-spacing: 1.12px;
    color: #CFDBD5;
    font-family: Lato_Black;
    cursor: pointer;

        &:hover{
            background-color: $---e8eddf_primary_color;
            color:$---242423_primary_surface;
        }

        &-studio-section{
            margin-top: 25px;
            margin-left: 0px;
            @media screen and (min-width:$lg) {
                margin-top: 100px;

            }
           
        }


        @media screen and (max-width:$s) {
            margin: auto;
            font-size: 10px;
            height: 38px;
            width: 210px;
        }

        @media screen and (max-width:($lg + 1)) and (min-width: ($s + 1)) {
            font-size: 10px;
            height: 38px;
            width: 210px;
            &:not(&-studio-section){
                margin-left: 40px;
            }
            margin-left: 0px;
        }

}


span.word-in-text-desc-default{
    font-family: Lato_Italic;
    color:#E8EDDF;
}


.hero-logo-container{
    flex: 1.6;
    width: 800px;
    height: 400px;
    transition: opacity 0.3s ease;
    z-index: 0;
    
    @include flex(row,center,start);
    

    @media screen and (max-width:($s + 1)) {
        flex: none;
        transition: none;
        width: clamp(200px,70%,280px);
        height: fit-content;
        position: relative;
        top: 50px;
    }

    @media screen and (max-width:389px) {
        width: clamp(200px,70%,230px);
    }

    @media screen and (max-width:($lg + 1)) {
        flex: 1 1 100px;
    }


    .helogo{
        // margin-top: 180px;
        max-width: 1000px;
        position: fixed;
        z-index: -1;
        right: 150px;

        @media screen and (min-width:($s + 1)) {
            width: 45vw;
        }

        @media screen and (max-width:1200px) {
            left: 47%;
        }

        
        @media screen and (max-width:1600px) {
            top: 130px;
        }

        @media screen and  (min-width:1601px)  {
            left:46%;
            top: 150px;
        }

        @media screen and (max-width:1450px) {
            top: 90px;
        }

        @media screen and (max-width:1250px) {
            top: 40px;
        }

        @media screen and (max-width:1150px) {
            top: 10px;
        }
        
        
        

        @media screen and (min-width:$lg) {
            width: 40vw;
           
            margin-left: 20px;
            right:270px;
        }

       

        @media screen and (max-width:$s) {
            position: static;
            order: 1;
            margin:0 auto;
            margin-top: 0;
            
        }

    }

}



.hero-logo-img{

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    z-index: 10;
    font-size: 160px;
    width: 950px;
    -webkit-text-stroke: 2px white;
    text-align: center;
    background-clip: text;
    background-image: linear-gradient(0deg,$primarySurface,$brandColor);
    -webkit-text-fill-color: transparent;
    letter-spacing: 20px;
    
    
}

