
.f_toast{
    display: block;
    position: fixed;
    bottom: -200px;
    z-index: 1000;
    transition: all 0.3s ease;
    left: 50%;
    transform: translateX(-50%);
    background-color: #242423;
    border: 1px solid rgb(77, 77, 77);
    border-radius: 4px;
    /* box-shadow: 7px 5px 7px rgba(114, 114, 114, 0.457); */
    cursor: pointer;
    display: block;
    bottom: 50px;
    border: none;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    animation-name: show;
    animation-direction: normal;
    animation-fill-mode: forwards;
    animation-delay: 0ms;
}



.f_toast_on{
    display: block;
    bottom: 0px;
}


.notification_close_btn{
    width: 20px;
    background-color: aliceblue;
    border: 1px solid black;
}


.notification_header{
    background-color: #242423;
    border: none;
}


.notification_brand_name{
    color: #E8EDDF;
}


@keyframes show {
    0%{
        bottom: -200px;
    }

    100%{
        bottom: 0px;
    }
}