.galleryBtn{
    border: 1px solid black;
    width: fit-content;
    padding: 4px;
    border-radius: 4px;
    margin-top: 10px;
    margin: auto;
    cursor: pointer;
    font-weight: bold;
    font-size: 20px;
    text-decoration: none;
    color: black;

    &:hover{
      background-color: rgb(71, 71, 71);
      color: white;
    }
}


.btn_add_container{
    position: absolute;
    top:78%;
    left: 50%;
    translate:-50% ;
}

.btn_add{
    color: white;
    appearance: none;
    padding: 4px;
     border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}