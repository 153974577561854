
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  &::-webkit-scrollbar{
    width: 0;
  }
}

body {
  overflow-y: scroll; /* Abilita lo scorrimento verticale solo per il corpo della pagina */
}

section:not(.inner-page-text-section){
  overflow: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}


 *{
   -webkit-touch-callout: none; /* iOS Safari */
   -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
       -ms-user-select: none; /* Internet Explorer/Edge */
           user-select: none;
 }


.innerBody{

  background-color: $primarySurface;
  font-size: 16px;
}



a{
  
  text-decoration: none;
}

ul{
  list-style: none;
}



/*
  Admin Table 
  tr
  th
  td
*/

.admin-table{
padding: 10px;

  th{
    text-align: center;
  }

  td{
    text-align: center;
    vertical-align: middle;
    min-width: 100px;

    &[style="cursor: pointer;"]:hover{
      background-color: rgb(215, 215, 215);
    }

    img{
      max-width: 60px;
    }
  }


  tr{

    &:nth-child(odd){
      td{
        background-color: rgba(201, 201, 201, 0.365);
      }
    }
  }

}

