.hashtag-tattoo-artist{
    font-size: 32px;
    color: #E8EDDF;
    font-family: Lato_Black;
    position: absolute;
    align-self: flex-end;
    left: 0;

    @media screen and (max-width:$s) {
        font-size: 24px;
    }
}