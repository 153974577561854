// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

// Buttons And Arrows Gallery Images

.btn-utility-gallery{
    height: 48px;
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: -15px;
    margin-bottom: 24px;
    position: relative;

    @media screen and (max-width:$s) {
        text-align: center;
         margin-top: -10px;
     }
}
/// button
.margin-top-58{
    margin-top: 58px;
}


.btns-container{
    width: 208px;
    height: 100%;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width:$s) {
        justify-content: right;
        gap:12px;
        
     }
    
}


.btn-gallery{
width:96px;
background-color:#333533;
height: 100%;
border-radius: 24px;
@include flex(row,center,flex-start);
cursor: pointer;


    &--right{
        justify-content: flex-end;
        border: 2px solid  $---818784_on_secondary_surface;
    }


    &:hover{
        border: 2px solid $---f5cb5c_secondary_color;

        & .arrow-line, .arrow-line::after, .arrow-line::before {
            background-color:$---f5cb5c_secondary_color ;
        }
    }


    @media screen and (max-width:$s) {
        width: 40px;
        height: 40px;
        border-radius: 50%;
 
     }
    
}





//arrow left



.arrow-line--left{
    background-color: $arrow-btn;
    display: block;
    width: 21px;
    height: 3px;
    margin-left: 15px;
    position: relative;
    border-radius: 24px;
    z-index: 1;

    @media screen and (max-width:$s) {
        margin-left: 9px;
  
      }

    
    &::before{
        content: '';
        width: 60%;
        height: 100%;
        position: absolute;
        transform: rotate(-45deg);
        left: -1px;
        background-color: $arrow-btn;
        top: -4px;
        border-radius: 24px;
        z-index: 0;      
    }


    &::after{
        content: '';
        width: 60%;
        height: 100%;
        position: absolute;
        transform: rotate(45deg);
        background-color: $arrow-btn;
        top: 4px;
        left: -1px;
        border-radius: 24px;
        z-index: 0;
    }


}


// arrow right

.arrow-line--right{
    background-color: $arrow-btn;
    display: block;
    width: 21px;
    height: 3px;
    margin-right: 15px;
    position: relative;
    border-radius: 24px;
    z-index: 0;

    @media screen and (max-width:$s) {
       margin-right: 9px;
 
     }

    
    &::before{
        content: '';
        width: 60%;
        height: 100%;
        position: absolute;
        transform: rotate(45deg);
        right: -3px;
        background-color: $arrow-btn;
        top: -4px;
        border-radius: 24px;
        z-index: 1;      
    }


    &::after{
        content: '';
        width: 60%;
        height: 100%;
        position: absolute;
        transform: rotate(-45deg);
        background-color: $arrow-btn;
        top: 4px;
        right: -3px;
        border-radius: 24px;
        z-index: 1;
    }
}