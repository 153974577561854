.container_scroll{
    max-width: 100vw;
    overflow-y: hidden;
    overflow-x: visible;
    margin-top: 40px;

    & table td{
        min-width: 123px;
    }
}

