.inner-page{
    height: 100%;
}



//padding left and right for all pages that need a padding (html class .padding-140)
.padding-140{
    padding-left: 140px;
    padding-right: 140px;

        @media screen and (max-width:$lg) {
            padding-left: 10px;
            padding-right: 10px;
        }
}



.padding-left-140{
    padding-left: 140px;
   


    @media screen and (max-width:$lg) {
        padding: 0 16px;
        
    }
}


.padding-top-93{
    padding-top: 93px;
}

//Hidden for Large screen
.hidden-LG{
    @media screen and (min-width:1025px) {
        display: none;
    }
}


.txt-center{
    text-align: center;
}





