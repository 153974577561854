:root{
    --text-inptDate : "Scegli il asdgiasorno";
}

#info{
    padding-top: 100px;

    @media screen and (max-width:$s) {
        padding-top: 40px;
        
    }
}

.info-body-inner--flex{
    display: flex;
    align-items: baseline;
    justify-content: baseline;
    padding-top: 104px;
    gap: 78px;
    position: relative;
    z-index: 1;

    @media screen and (max-width:$s) {
        flex-direction: column;
        padding-top: 64px;
        
    }
    
}



.info-container{
    width: 536px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    @media screen and (max-width:$s) {
        width: 100vw;
        padding-right: 32px;
        
    }

    
   
}



.info-title{
    color: $text-descriptionTitlePage;
    font-family: Lato_Black;
    font-size: 24px;
    font-weight: 900;

    @media screen and (max-width:$s) {
        margin: auto; 
        width: 100%;
        text-align: center;
    }
}


.info-form-info{
    color: $text-input-form;
    padding-top: 14px;
    line-height: 22px;
    font-size: 20px;
    font-family: Lato_Regular;
    font-style: normal;
    font-weight: normal;
    width: 87%;

    @media screen and (max-width:$s) {
       margin: auto;
        width: 100%;
        text-align: center;
        max-width: 470px;
    }
    
}



form.form-contact{
    display: flex;
    flex-direction: column;
    width: 103%;
    gap: 24px;
    padding-top: 30px;
    font-family: Lato_Regular;
    font-size: 14px;
    position: relative;

        input{
            
            appearance: none;
            border: none;
            width: 50%;
            border-radius: 4px;
            text-indent: 24px;
            letter-spacing: 1.12px;

                &#input-date, &#input-hour,&#submit{
                    width: 252px;

                    @media screen and (max-width:$s)
                     {
                        width: 100%;
                        display: block;
                    }
                }
        }

        textarea{
            width: 100%;
            appearance: none;
            border: none;
            border-radius: 4px;
            max-height: 160px;
            padding: 50px;
            color: $text-descriptionTitlePage;
            background-color: $input-background-form;
            letter-spacing: 1.12px;
            padding: 20px;
            outline: none;

                &:focus{
                   outline: 1px solid white;
                }
                
                &.warning{
                    outline: 1px solid $warning-color;
                }
        }

    
        @media screen and (max-width:$s) {
            padding-left: 8px;
            padding-right: 8px;
         }

         
}






.form-header-inputs{
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media screen and (max-width:$s) {
       gap: 10px;
     }
}

.form-header-top{
    position: relative;
}



.form-header-top,.form-header-bottom,.form-footer-inputs{
  display: flex;
  justify-content: space-between;
  align-items: center;  
}


label[for=input-file]{
    background-color: $input-background-form;
    @include flex(row,center,space-evenly);
    border-radius: 4px;
    outline: 1px solid $text-input-form;
    cursor: pointer;
    padding-right: 5px;
    height: 60px;

        .upload-img{
            height: 70%;
            width: 28px;
            background-image: url(/public/assets/icons/upload-image_icon.svg);
            background-repeat: no-repeat;
            background-position: center center;
            margin-left: 7px;
            background-size: cover;
            border-radius: 4px;
           
        }

        &:active{
            outline: 1px solid $text-input-form;
        }
}

#upload-container{
    width: 35px;

}


input[type=file]{
    display: none;  
}


.input{
    background-color: $input-background-form;
    color: $text-input-form;
    height: 100%;
    width: 50%;
    outline: none;

    

    &:focus{
        outline: 1px solid white;
    }
    
    &.warning{
        outline: 1px solid $warning-color;
    }

    &[type=time]{
   
        height: 48px;
        position: relative;
        color: transparent;
        appearance: none;
        
    
            &::after{
                content: '';
                width: 20px;
                height: 20px;
                border: 2px solid white;
                position: absolute;
                display: flex;
                align-items: center;
                right: 20px;
                transform: rotate(-45deg);
                border-right: none;
                border-top: none;
                top: 10px;
            }   

    }
       
    &[type=date]{
       
        height: 48px;
        position: relative;
        color: transparent;
        appearance: none;
            &::after{
                content: '';
                width: 20px;
                height: 20px;
                border: 2px solid white;
                position: absolute;
                display: flex;
                align-items: center;
                right: 20px;
                transform: rotate(-45deg);
                border-right: none;
                border-top: none;
                top: 10px;
            }   
        }
}


input[type=date]::-webkit-calendar-picker-indicator{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 10;
    opacity: 0;
    cursor: pointer;
    height: 48px;
}


input[type=time]::-webkit-calendar-picker-indicator{
    height: 80%;
    width: 100%;
    position: relative;
    z-index: 10;
    opacity: 0;
    cursor: pointer;
    height: 48px;
}



.inpt-container-inpt-container--relative{
    position: relative;  
}


label[for=input-date]{
    color: #818784;
    position: absolute;
    z-index: 10; 
    padding: 16px;
    margin-left: 7px;
    outline: none;
}

label[for=input-hour]{
    color: #818784;
    position: absolute;
    z-index: 10;
    padding: 16px;
    margin-left: 7px;
    outline: none;
}

.form-footer-inputs{
  
    height: 50px;

        label,#submit{
            height: 46px;
        }
}



#submit{
    border-radius: 24px;
    @include flex(row,center,center);
    text-indent: 0;
    font-style: normal;
    font-weight: 900;
    font-family: Lato_Black;
    font-size: 14px;
    letter-spacing: 1.12px;
    color: $primarySurface;
    background-color: $background_Btn_Nav;
    border: 2px solid $background_Btn_Nav;
    cursor: pointer;
}



.inputs-container{
    height: 48px;
}

.form-warning-text{
    color: $warning-color;
    letter-spacing: 1px;
    font-family: Lato_Regular;
    display: none;
    
    &.warning{
        display: block;
    }
}




@media screen and (max-width:$s) {
    .inpt-date-container{
        width: 100%;
    }

    .form-header-top, .form-header-bottom, .form-footer-inputs {
        display: flex;
        /* justify-content: space-between; */
        /* align-items: center; */
        flex-direction: column;
        gap: 10px;
        height: 100%;
    }   

    .form-footer-inputs {
        gap: 24px;
    }

   
    form.form-contact input, label[for=input-file]{
        width: 100%;
        height: 48px;
    }


    label[for=input-file]{
        justify-content: center;
        gap: 10px;
        height: 60px;
    }


    input[type=email]{
        order: 2;
    }


    form.form-contact{
        gap: 10px;
        max-width: 60%;
        margin: auto;
    }


    .text-info-item-openTime{
        width: 0%
    }


    @media screen and (max-width:640px) {
        form.form-contact{
            
            max-width: 100%;
            
        }
    }

    
}



/*---------------------------------------------------------------------------------
                            SECOND INFO BOX 
---------------------------------------------------------------------------------*/





.info-item-container{
     @include flex(row,center,left);
     margin-top: 50px; 
     text-wrap: balance;
     @media screen and (max-width:$s) {
        flex-direction: column;
        text-align: center;
        margin-top: 34px;
        gap: 10px;
     }

}



.text-info-item{
    color: $text-input-form;
    font-style: normal;
    font-family: Lato_Regular;
    margin-left: 25px;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;

   
        &-link{
            text-decoration: underline;
        }

        &-openTime{
            min-width: 250px;
            time ,span{
                color: #E8EDDF;
            }
        }

        @media screen and (max-width:$s) {
            margin: 10px 0;
         }
}


.info-container2{
flex: 1.5;
    
    & .icon-item{
        background-size: contain;
        background-repeat: no-repeat;
        &--1{
            background-image: url(/public/assets/icons/icon_footer_location.svg);
            width: 32px;
            height: 32px;
        }

        &--2{
            background-image: url(/public/assets/icons/icon_footer_phone.svg);
            width: 32px;
            height: 32px;
        }

        &--3{
            background-image: url(/public/assets/icons/icon_footer_mail.svg);
            width: 32px;
            height: 26px;
        }

        &--4{
            background-image: url(/public/assets/icons/icon_footer_WA.svg);
            width: 32px;
            height: 32px;
        }

        &--5{
            background-image: url(/public/assets/icons/icon_footer_IG.svg);
            width: 32px;
            height: 32px;
        }

        


    }

}


.info-container3{
    flex: 2;

    & .icon-item--6{
        background-image: url(/public/assets/icons/icon_footer_clock.svg);
        width: 42px;
        height: 32px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
    }
}


.info-title-map{
    margin-top: 61px;

    margin-bottom: 10px;
}



.map{
    border-radius: 16px;
    height: 252px;
    max-height: 252px;
    background-size: cover;
    max-width: 800px;

  @media screen and (max-width:$s) {
   max-width: 460px;
   margin: auto;
}

}


