.title-page{
    color:$text-titlePage;
    font-family: Lato_Regular;
    font-size: 16px;
    letter-spacing: 1.28px;
    font-style: normal;
    padding-top: 34px;
    text-align: initial;

    @media screen and (max-width:$s) {
        text-align: center;
         
     }
}


.description-title-page{
    font-size: 48px;
    font-family: LAto_Black;
    font-style: normal;
    font-weight: 900;
    padding-top: 34px;
    color: $text-descriptionTitlePage;
    text-align: initial;

    @media screen and (max-width:$s) {
        text-align: center;
        padding: 20px 0 25px 0;
     }
     
}



.white-rose--left{
    position: relative;

    &::after{
        content: '';
        width: 223px;
        height: 1400px;
        position: absolute;
        top: 50px;
        left: -210px;
        background-image: url(/public/assets/svg/Rose_Leaves_1.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top right;
    }

}



.separator-in-page{
    width: 96px;
    margin-top: 24px;
    border: 2px solid $brandColor;
    border-radius: 24px;

    @media screen and (max-width:$s) {
       margin: auto;
         
     }
}


.inner-gallery-page{
    padding-top: 70px;

        @media screen and (max-width:$s) {
            padding-top: 18px;
        }
}


img{
    position: relative;
    z-index: 0;
}