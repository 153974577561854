/* Colors: */
$---818784_on_secondary_surface: #818784;
$---cfdbd5_tertiary_color: #CFDBD5;
$---e8eddf_primary_color: #E8EDDF;
$---f5cb5c_secondary_color: #F5CB5C;
$---333533_secondary_surface: #333533;
$---242423_primary_surface: #242423;

/* Font/text values */
$--unnamed-font-family-lato: Lato;
$--unnamed-font-style-normal: normal;
$--unnamed-font-style-italic: italic;
$--unnamed-font-weight-300: 300px;
$--unnamed-font-weight-900: 900px;
$--unnamed-font-weight-normal: normal;
$--unnamed-font-size-14: 14px;
$--unnamed-font-size-16: 16px;
$--unnamed-font-size-20: 20px;
$--unnamed-font-size-24: 24px;
$--unnamed-font-size-32: 32px;
$--unnamed-font-size-48: 48px;
$--unnamed-font-size-140: 140px;
$--unnamed-character-spacing-0: 0px;
$--unnamed-character-spacing-1-12: 1.12px;
$--unnamed-character-spacing-0-48: 0.48px;
$--unnamed-character-spacing--2-8: -2.8px;
$--unnamed-character-spacing-1-28: 1.28px;
$--unnamed-character-spacing-1-92: 1.92px;
$--unnamed-line-spacing-22: 22px;
$--unnamed-line-spacing-34: 34px;
$--unnamed-line-spacing-42: 42px;
$--unnamed-line-spacing-126: 126px;
$--unnamed-text-transform-uppercase: uppercase;


/* Character Styles */
.h6_lato_black-—-32pt {
font-family: var(--unnamed-font-family-lato);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-900);
font-size: var(--unnamed-font-size-32);
line-height: var(--unnamed-line-spacing-34);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(---e8eddf_primary_color);
}
.caption_lato-—-14pt {
font-family: var(--unnamed-font-family-lato);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-normal);
font-size: var(--unnamed-font-size-14);
line-height: var(--unnamed-line-spacing-34);
letter-spacing: var(--unnamed-character-spacing-1-12);
color: var(---818784_on_secondary_surface);
}
.h5_lato_regular-—-20pt {
font-family: var(--unnamed-font-family-lato);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-300);
font-size: var(--unnamed-font-size-20);
line-height: var(--unnamed-line-spacing-22);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(---cfdbd5_tertiary_color);
}
.h4_lato_black-—-24pt {
font-family: var(--unnamed-font-family-lato);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-900);
font-size: var(--unnamed-font-size-24);
line-height: var(--unnamed-line-spacing-34);
letter-spacing: var(--unnamed-character-spacing-0-48);
color: var(---e8eddf_primary_color);
text-transform: var(--unnamed-text-transform-uppercase);
}
.h6_lato_regular-—-24pt {
font-family: var(--unnamed-font-family-lato);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-normal);
font-size: var(--unnamed-font-size-24);
line-height: var(--unnamed-line-spacing-34);
letter-spacing: var(--unnamed-character-spacing-1-92);
color: var(---e8eddf_primary_color);
text-transform: var(--unnamed-text-transform-uppercase);
}
.h2_lato_black-—-48pt {
font-family: var(--unnamed-font-family-lato);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-900);
font-size: var(--unnamed-font-size-48);
line-height: var(--unnamed-line-spacing-34);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(---e8eddf_primary_color);
}
.menu_lato_regular-—-16pt {
font-family: var(--unnamed-font-family-lato);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-normal);
font-size: var(--unnamed-font-size-16);
line-height: var(--unnamed-line-spacing-34);
letter-spacing: var(--unnamed-character-spacing-1-28);
color: var(---cfdbd5_tertiary_color);
text-transform: var(--unnamed-text-transform-uppercase);
}
.button_lato_black-—-14pt {
font-family: var(--unnamed-font-family-lato);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-900);
font-size: var(--unnamed-font-size-14);
line-height: var(--unnamed-line-spacing-34);
letter-spacing: var(--unnamed-character-spacing-1-12);
color: var(---e8eddf_primary_color);
text-transform: var(--unnamed-text-transform-uppercase);
}
.h3_lato_italic-—-32pt {
font-family: var(--unnamed-font-family-lato);
font-style: var(--unnamed-font-style-italic);
font-weight: var(--unnamed-font-weight-normal);
font-size: var(--unnamed-font-size-32);
line-height: var(--unnamed-line-spacing-42);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(---e8eddf_primary_color);
}
.h3_lato_regular-—-32pt {
font-family: var(--unnamed-font-family-lato);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-normal);
font-size: var(--unnamed-font-size-32);
line-height: var(--unnamed-line-spacing-42);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(---e8eddf_primary_color);
}
.h1_lato_black-—-140pt {
font-family: var(--unnamed-font-family-lato);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-900);
font-size: var(--unnamed-font-size-140);
line-height: var(--unnamed-line-spacing-126);
letter-spacing: var(--unnamed-character-spacing--2-8);
color: var(---e8eddf_primary_color);
text-transform: var(--unnamed-text-transform-uppercase);
}