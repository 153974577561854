.recaptcha_back{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.548);
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    animation: fadeIn 0.5s ease-in-out;
    color: rgb(0, 0, 0);
}


@keyframes fadeIn {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
    
}

.recaptcha_container{
    width: 100%;
    max-width: 500px;
    background-color: white;
    border-radius: 4px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
}


.text_rcp_modale{
    margin-top: 16;  
}

.text_rcp_modale span{
    color: red;  
   
}

#input-VerificationEmail{
    margin-bottom: 16px;
}

.btn-close_rcp{
    border: 1px solid #030303;
}