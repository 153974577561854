.modale{
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.948);
    top: 0;
    z-index: 20;
    @include flex(row,center,center);
    display: none;
    font-family: Lato_Regular;
   

    &-container{
        
        

        @media screen and (min-width:641px) {
            width: 80%;
        }

        @media screen and (min-width:$s) {
            width: 60%;
        }

        @media screen and (min-width:$m) {
            width: 50%;
        }

        @media screen and (min-width:$lg) {
            width: 45%;
        }
    }

    button{
        height: 30px;
        width: 30px;
        flex: 0 0 30px;
        font-weight: bold;
        cursor: pointer;
        appearance: none;
        border-radius: 50%;
        border: none;
        position: absolute;
        top: 10px;
        right: 2vw;
        color: rgba(255, 255, 255, 0.829);
        background-color: rgba(27, 24, 24, 0.853);
            &:hover{
                background-color: rgba(27, 24, 24, 0.253);
                color: white;
            }
    }

    &-prev-img-btn{
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        left: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(27, 24, 24, 0.253);
        
        @media screen and (min-width:$lg) {
            left: 5%;
        } 
    }

    &-next-img-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        background-color: rgba(27, 24, 24, 0.253);
        border-radius: 50%;
        right: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;


        @media screen and (min-width:$lg) {
            right: 5%;
        }
    }
}

.modale-img{
    // aspect-ratio: 9/16;
    object-fit: contain;
    border-radius: 0;
 
    width: 100vw;
    height: 100vh;
    left: 50%;
    transform: translateX(-50%);
    
    // @media screen and (min-width: 700px) {
    //     width: 70vw;
    //     left: 50%;
    //     transform: translateX(-50%); 
    // }

    // @media screen and (min-width: 900px) {
    //     width: 60vw;
    //     left: 50%;
    //     transform: translateX(-50%); 
    // }

    // @media screen and (min-width: 1000px) {
    //     width: 50vw;
    //     left: 50%;
    //     transform: translateX(-50%); 
    //     max-width: 600px;
    // }
}

.modal-horz{
    border-radius: 0;
    height: 100%;
    left: 50%;
    transform: translateX(-50%); 
    width: 100vw;
    max-width: 1920px;
    object-fit: cover;
    // @media screen and (max-width:370px) {
    //     height: 35vh;
    //     max-height: 200px;
    //     min-height: 200px;
    // }

    // @media screen and (min-width:371px) {
    //     height: 40vh;
    //     max-height: 290px;
    //     max-width: 1000px;
    //     width: 100vw;
    //     min-height: 250px;
    // }

    // @media screen and (min-width:470px) {
    //     height: 80vh;
    //     max-height: 300px;
    //     max-width: 450px;
    //     width: 100vw;
    // }

    // @media screen and (min-width:700px) {
    //     height: 80vh;
    //     max-height: 400px;
    //     max-width: 600px;
    //     width: 100vw;
    // }

    // @media screen and (min-width:900px) {
    //     height: 90vh;
    //     max-height: 500px;
    //     max-width: 800px;
    //     width: 100vw;
    // }
    
}

// #modal-horz{
//     height: 45vh;
    
//     @media screen and (max-width:641px){
//         height: 37vh;
//         width: 100vw;
//         border-radius: 0;
//     }
// }

