.page_container{
    height: 100vh;
    position: relative;
}

.btn_add_container{
    position: absolute;
    top:78%;
    left: 50%;
    translate:-50% ;
}

.btn_add{
    color: white;
    appearance: none;
    padding: 4px;
     border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.input_container{
    min-width: 300px;
    max-width: 800px;
    
}

.carousel{
    overflow-y: hidden;
    overflow-x: auto;
}


.carousel img:not(.icon){
  min-width: 250px;
  max-width: 250px;
  max-height: 350px;
  border:1px solid black;
  margin: 10px;
  aspect-ratio: 1/2;
  background-color: rgb(41, 41, 41);
  object-fit: cover;
  padding: 2px;
}



.title,.desc{
    position: absolute;
    z-index: 10;
    color: rgb(0, 0, 0);
    opacity: 0;
    
}

.title{
   font-weight: bold;
    top: 50%;
    left: 10%;
}

.desc{
    top: 60%;
    left: 10%;
}

.hoverable:hover{
    cursor: pointer;
}

.hoverable:hover img{
    opacity: 0.5;
}

.hoverable:hover .desc{
    opacity: 1;
}

.hoverable:hover .title{
    opacity: 1;
}

.icons_container{
    position: absolute;
    top: 5%;
    z-index: 10;
    display: flex;
    gap: 20px;
    left: 65%;
}

.icons_container span{
    width: 30px;
    height: 30px;
    background-color: rgb(102, 169, 245);
    border: 1px solid black;
    border-radius: 4px;
    box-shadow: 0 0 10px;
}

.icons_container span:nth-child(2){
    width: 30px;
    height: 30px;
    background-color: rgb(220, 94, 94);
    border: 1px solid black;
}

.icons_container img{
    min-width: 30px;
}

.icons_container:hover img{
   opacity: 1;
}