
// flex center
@mixin flexCenter{
display: flex;
justify-content: center;
align-items: center;
}



//flex to modify
@mixin flex($direction,$align,$justify){
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  }


  @mixin flexCenterCol{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    }