// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------


.footer{
width: 100vw;
// background-color: $primarySurface;
margin-top: 150px;
position: relative;
}

.inner-footer--flex{
    @include flex(row,center,space-between);
    padding-bottom: 50px;
    padding-top: 50px;
    
        @media screen and (max-width:$s) {
            flex-direction: column;
            gap: 24px;
            margin: 40px 0 0px 0;
        }
}


.copyright,.p-iva,.p_privacy{
    color: #E8EDDF;
    letter-spacing: 1.28px;
    font-family: Lato_Light;
    font-size: 16px;
}


.footer-icons-socialNetwork{
       
        a{
          
            display: inline-block;
            width: 32px;
            height: 32px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: bottom center;
            margin: 0 10px;
            
                &:nth-child(1){
                    background-image: url(/public/assets/icons/icon_footer_WA.svg);
                }

                &:nth-child(2){
                    background-image: url(/public/assets/icons/icon_footer_IG.svg);
                }

                &:nth-child(3){
                    background-image: url(/public/assets/icons/facebook_footer.svg);
                    }
        }
        
        
       
}