// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.header{
    color: aliceblue;
    padding-top: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position:sticky;
    top: 0;
    width: 100vw;
    height: 98px;
    background-color: $primarySurface;
    z-index: 20;
    transition: top 0.3s ease;

    @media screen and (max-width:$s) {
       padding: 0;
      
    }
     &.scroll-to-top{
        top: -100%
     }
    
}


.header-right-elements{
    @include flex(row,center,right);
   gap: 50px;

   @media screen and (min-width:768px) and (max-width:1024px)  {
    gap: 5px;
   }
}


.logo img{
    @media screen and (max-width:$s) {
      padding: 0 0 0 16px;
      height: 60px;
     }
}





