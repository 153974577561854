.main{
    position: relative;
}


.page{
    
    width: 100vw;

    &:not(#default){
        padding-top: 50px;
    }

  
}



.white-rose--left--bottom{

        width: 160px;
        height: 1500px;
        position: absolute;
        bottom: 980px;
        left: -35px;
        background-image: url(/public/assets/svg//Rose_Leaves_2.svg);
        background-repeat: no-repeat;
        background-size: 170%;
        background-position: center right; 
        z-index: -1;  
        
        // @media screen  and (max-width:$lg){
        //     bottom: -4400px;
           
        // }

        // @media screen  and (max-width:$m){
        //     bottom: -3900px;
           
        // }

        @media screen  and (max-width:680px){
            display: none;
           
        }
}


.white-rose--right{

    width: 300px;
    height: 500px;
    position: absolute;
    top: 4518px;
    right: 5px;
    background-image: url(/public/assets/svg/3_Rose.svg);
    background-repeat: no-repeat;
    background-size: 125% ;
    background-position: 0% 0%;     
    z-index: -1;
    
    @media screen  and (max-width:$lg){
        display: none;
    }
}
