

.studio-gallery-slider--grid{
    display: grid;
    gap: 16px 17px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    grid-template-columns: repeat(3, calc(33vw - 107px));
    grid-template-rows: 326px 326px 260px 260px 260px 260px ;
    grid-template-areas: 

    "a b c "
    "a b c "
    "d e f "
    "g h f "
    "i l m "
    "i n o" 
    
    ;


    @media screen  and (max-width:$s){
        display: flex;
        flex-direction: column;
        max-width: 464px;
        gap: 16px 17px;
        margin: auto;
        position: unset;
        transform: translateX(0%);
    }

}


.grid-1{
    grid-area: a;
   
}

.grid-2{
    grid-area: b;
}

.grid-3{
    grid-area: c;
}

.grid-4{
    grid-area: d;
}

.grid-5{
    grid-area: e;
}

.grid-6{
    grid-area: f;
}

.grid-7{
    grid-area:g;
}

.grid-8{
    grid-area: h
}

.grid-9{
    grid-area: i;
}

.grid-10{
    grid-area: l;
}

.grid-11{
    grid-area: m;
}

.grid-12{
    grid-area: n;
}

.grid-13{
    grid-area: o;
}


@media screen  and (max-width:$s){
    .studio-image-list{

        img{
            object-fit: cover;
        }
    }

   

    .img-hidden{
        display: none;
    }
  
}



.studio-card-desc{
    
    height: 100%;
    padding: 0 4px;
    border-radius: 16px;

        @media screen and (max-width:$s) {
            padding-bottom: 76px;
        }
    
    }
    
        .studio-card-text{
            font-size: clamp(20px,1.7vw,40px);
            color: #E8EDDF;
            letter-spacing: 0.4px;
            font-style: normal;
            font-weight: 300;
            font-family: Lato_Light;
            

            @media screen and (max-width:$s) {
                font-size: 24px;
                text-align: center;
                padding: 0px 14px 34px 14px;
                width: 100%;
                margin: auto;
                margin-top: 0px;
                line-height: 28px;
                letter-spacing: 0.24px;
            }
            
        }


.btn-show-studio-img{
    margin-top:32px;
  
    @media screen and (min-width : 1024px) {
        display: none;
    }
}

.btn-underTitle{
    text-decoration: none;
}


.studio-image-list{
    cursor: pointer;
    max-width: 800px;
}


.studio-img-vtl{
    @media screen and (min-width: 320px)  and (max-width:920px) {
        height: 100vh;
        min-height: 400px;
        max-height: 400px;
        width: 80vw;
        margin: auto;
    }

    @media screen and (min-width: 420px)  and (max-width:920px) {
        height: 100vh;
        min-height: 400px;
        max-height: 500px;
        width: 80vw;
        margin: auto;
    }

    @media screen and (min-width: 520px)  and (max-width:920px) {
        height: 100vh;
        min-height: 400px;
        max-height: 500px;
        width: 70vw;
        margin: auto;
    }

    @media screen and (min-width: 620px)  and (max-width:920px) {
        height: 100vh;
        min-height: 400px;
        max-height: 500px;
        width: 60vw;
        margin: auto;
    }

    @media screen and (min-width: 720px)  and (max-width:920px) {
        height: 100vh;
        min-height: 400px;
        max-height: 500px;
        width: 50vw;
        margin: auto;
    }

    @media screen and (min-width: 820px)  and (max-width:920px) {
        height: 100vh;
        min-height: 400px;
        max-height: 500px;
        width: 45vw;
        margin: auto;
    }

    @media screen and (min-width: 920px) and (max-width:920px) {
        height: 100vh;
        min-height: 400px;
        max-height: 500px;
        width: 40vw;
        margin: auto;
    }
   
}



.studio-img-hrz{
    @media screen and (max-width: $s)  {
        height: 250px;
    }
}

