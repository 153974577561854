@charset "UTF-8";

// 1. Configuration and helpers
@import
  'abstracts/variables',
  'abstracts/functions',
  'abstracts/mixins',
  'adobeXD';

// 2. Vendors
@import
  'vendors/normalize';

// 3. Base stuff
@import
  'base/base',
  'base/fonts',
  'base/typography',
  'base/helpers';

// 4. Layout-related sections
@import
  'layout/header',
  'layout/footer',
  'layout/main',
  'layout/default',
  'layout/gallery',
  'layout/studioPage',
  'layout/modale',
  'layout/infoPage';



// 5. Components
@import
  'components/button',
  'components/navigation',
  'components/carousel-galleria',
  'components/artist_carousel',
  'components/table';


// 7. Responsive

@import 
  'responsive/responsive';

p{
    margin-bottom: 0;
}

ul.artist-social-networks-inner,ul.studio-gallery-slider{
  padding-left: 0rem;
}

#header__nav{
 display:block;
}

#nav-container{
  padding-left: 0;
}