// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@font-face {
    font-family: Lato_Black;
    src: url(/public/assets/fonts/Lato/Lato-Black.ttf) format("truetype");
    font-display: swap;
}

@font-face{
    font-family: Lato_Regular;
    src: url(/public/assets/fonts/Lato/Lato-Regular.ttf) format("truetype");
    font-display: swap;
}


@font-face{
    font-family: Lato_Italic;
    src: url(/public/assets/fonts/Lato/Lato-Italic.ttf) format("truetype");
    font-display: swap;
}

@font-face{
    font-family: Lato_Light;
    src: url(/public/assets/fonts/Lato/Lato-Light.ttf) format("truetype");
    font-display: swap;
}


