



$primarySurface: #242423;


$brandColor:#F5CB5C;


$textNav:#CFDBD5;

$borderColor:#818784;

$background_Btn_Nav:#E8EDDF;

$text-titlePage:#CFDBD5;



$text-descriptionTitlePage:#E8EDDF;

$arrow-btn:#818784;


$input-background-form:#333533;
$text-input-form:#818784;

$warning-color: rgb(238, 93, 136);


//breakpoints


$xl:1920px;
// < 1568px 
$lg: 1568px;

$m: 1200px;

$s: 1024px;

$xs:768px;

