.artist-gallery-section{
    height: fit-content;
    position: relative;
    z-index: 0;
}



.carousel-container-artist-outer{
    height: 560px;
    position: relative;

    @media screen and (max-width:$lg) {
        height: fit-content;
        
    }
    
}

.inner-carousel{
    height: inherit;
    display: flex;
    gap: 15px;
}



.artist-card-container, .outer-figure{
    width: 397px;
    height: 100%;
    
    @media screen and (max-width:$lg) {
        height: 345px;
        width: 266px;
        
    }
}



//***  FIRST TATTOO ARTIST CARD IN TATUATORI SECTION // STEVE

.artist-card{
    height: inherit;
    width: inherit;
    position: relative;
    border-radius: 16px;
    box-shadow: 20px 0 100px rgb(0, 0, 0);
    z-index: 10;

    // inset shadow in artist card
    &::after{
        content: '';
        height: inherit;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        border-radius: 16px;
        opacity: 1;
        border: 8px solid #E8EDDF;  
        mix-blend-mode: multiply;
        background-color: #818784;
    }

        .artist-img{
            object-fit: cover;
            object-position: 40%;
            position: relative; 
        }
    
}




//*** SECOND TATTOO ARTIST CARD IN TATUATORI SECTION  //DANIELA

.artist-card--second{
    

    // inset shadow in artist card

    &::after{
        background-color: #9D7E2E;
    }

        .artist-img{
         object-position: 50%;
        }
    
}



//**** THIRD TATTOO ARTIST CARD IN TATUATORI SECTION // PEPPE

.artist-card--third{
   
    // inset shadow in artist card

    &::after{
        background: linear-gradient(146deg,#000000,#FFFFFF33);
        opacity: 0.8;
    }


        .artist-img{
            object-position: 0%;
            
        }
    
}





.artist-card-name{
    font-size: 80px;
    font-family: Lato_Black;
    width: fit-content;
    word-wrap: break-word;
    padding: 18px;
    color: #E8EDDF;
    z-index: 10;
    font-weight: 900;
    line-height: 85%;
    position: absolute;
    top: 12px;
    z-index: 12;

    @media screen  and (max-width:$lg){
        padding: 5px 18px;
        font-size: 50px;
    }

    @media screen  and (max-width:680px){
        display: none;
    }
}



.artist-social-networks-outer{
    
    position: absolute;
    width: 48px;
    height: 168px;
    background-color: #E8EDDF;
    border-radius: 24px;
    box-shadow: 0 4px 8px #00000029;
    left: 18px;
    bottom: 110px;
    z-index: 11;
    position:absolute;

        &--bottom{
            bottom: 180px;
        }

        @media screen and (max-width:$lg) {
          left: 18px;
          bottom: 12px;
          width: 42px;
          height: 148px;
          z-index: 11;
        }

}




ul.artist-social-networks-inner{
    @include flex(column,center,space-evenly);
   height: inherit;
}




.card-social-network-item{

width: 32px;
height: 32px;
position: relative;

    .card-social-network-item--link{
        display: block;
        width: inherit;
        height: inherit;
    
    }

    &:nth-child(1){
        background-image: url(/public/assets/icons/WA_icon_Inactive.svg);
        
        &:hover{
            background-image: url(/public/assets/icons/WA_icon_Active.svg);
        }
    }


    &:nth-child(2){
        background-image: url(/public/assets/icons/IG_icon_Inactive.svg);

        &:hover{
            background-image: url(/public/assets/icons/IG_icon_Active.svg);
        }
    }

    &:nth-child(3){
        background-image: url(/public/assets/icons/FB_icon_Inactive.svg); 

        &:hover{
            background-image: url(/public/assets/icons/FB_icon_Active.svg);
        }
    }

}



.artist-slider-carousel-container,.slider-carousel-inner,figure.img-container--slider-tatuatori{
    height: inherit; 
}



.slider{
    display: flex;
    gap: 15px;
    width: 100vw;
   
}


.img-tatoo{
object-fit: cover;
}

.object-fit--cover{
    object-fit: cover;
}



figure.img-container--slider-tatuatori{
    position: relative;     
    max-width: 397px;
    cursor: pointer;
    flex-shrink: 0;
    transition: opacity 0.4s;
    
    @media screen and (max-width:$lg) {
        height: 100%;
        width: 266px;
    }
        

        &:hover > .img-tatoo{
            opacity: 0.7;
        }

        &:hover .hover-background{
            width: inherit;
            height: inherit;
            position: absolute;
           
            background-color: #202020a4;
            z-index: 0;
            top: 0;
            left: 0;
            opacity: 1;
            transition: all 0.3s;
        }

        &:hover section {
            opacity: 1;
        }

         .btn-mobile-showText{
           
                border-radius: 50%;
                background-color:#CFDBD5;
                width: 48px;
                height: 48px;
                position:absolute;
                z-index: 10;
                bottom:10px;
                right:10px;
                display: flex;
                justify-content: center;
                align-items: center;
                
                span{
                    width: 50%;
                    height: 3px;
                    background-color: #333333;
                    border-radius: 16px;
                    
                }
                &-second-line{
                    background-color: #333333;
                    position: absolute;
                    transform: rotate(90deg);

                    &-off{
                        display: none;
                    }
                }


                &-container{
                    border-radius: 50%;
                    width: 80px;
                    height: 80px;
                    position:absolute;
                    z-index: 10;
                    bottom:0px;
                    right:0px;
  
                }
                
           
        }

}




figcaption.img-tatoo-description--hover{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include flex(column,center,center); 
   
    & * {
        transition: all 0.5s;
    } 

    &-mobile {
        .img-tatoo{
            opacity: 0.7;
        }

         .hover-background{
            width: inherit;
            height: inherit;
            position: absolute;
            border-radius: 24px;
            background-color: #202020a4;
            z-index: 0;
            top: 0;
            left: 0;
            opacity: 1;
            transition: all 0.3s;
        }

         section {
            opacity: 1;
        }
    }
} 

figcaption.img-tatoo-description--hover{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include flex(column,center,center); 
}

figcaption.img-tatoo-description--hover-mobile{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include flex(column,center,center); 
}




.details-card--hover,.description-card--hover{
    color: #CFDBD5;
    font-size: 16px;
    font-family: Lato_Regular;
    letter-spacing: 1.28px;
    font-weight: normal;
    font-style: normal;
    line-height: 0;
    z-index: 11;
    
}

.details-card--hover{
    position: relative;
    @media screen and (max-width:$m) {
        display: none;
    }
}

.icon-details-container--hover{
    opacity: 0;
}


.description-card-container--hover{
    position: absolute;
    bottom: 0;
    padding: 50px 50px;
    color: #E8EDDF;
    opacity: 0;
}



.hashtag-card--hover{
    margin-bottom: 25px;
    font-size: 32px;
    font-family: Lato_Black;
    font-weight: 900;
    font-style: normal;
    
}



.description-card--hover{
    line-height: 24px;
    letter-spacing: 0;
}




// scroll artist tatoo img carousel

.artist-slider-carousel-container{

   overflow-y: hidden;
   overflow-x: scroll;

   &::-webkit-scrollbar{
    display: none;
   }
}






//From Javascript change opacity when card position X < 220

.lower-opacity{
    opacity: 0.3;
    transition: opacity 0.5s ease;
}
    
