.form-loading {
    width: 100%;
    height: 20px;
    background-color: #f2f2f2;
    border-radius: 4px;
    overflow: hidden;
}

.progress-bar {
    height: 100%;
    background-color: #007bff;
    transition: width 0.3s ease-in-out;
}

